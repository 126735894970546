const awsConfig = {
  Auth: {
    Cognito: {
      identityPoolId: 'eu-west-1:6c473657-ea5d-413e-839b-afab97822101', //REQUIRED - Amazon Cognito Identity Pool ID
      region: 'eu-west-1', // REQUIRED - Amazon Cognito Region
      userPoolId: 'eu-west-1_dYzw3vkaq', //OPTIONAL - Amazon Cognito User Pool ID
      userPoolClientId: '1ucri5kg696tmsalps2kmcqql6', //OPTIONAL - Amazon Cognito Web Client ID
    }
  },
  Storage: {
    AWSS3: {
      bucket: 'production-referencecatalog-s3-bucket',
      region: 'eu-west-1', //OPTIONAL -  Amazon service region
    }
  },
  region: 'eu-west-1',
};
export default awsConfig;